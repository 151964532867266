const apiKey = "kpjhealth";

const api_config = {
  headers: {
    "X-API-Key": apiKey,
  },
};

// local
// const api_server = "http://localhost/kpjapi/api/";
// staging
const api_server = "https://beta-api.kpjhealth.com.my/api/";
// production
// const api_server = "https://vfapi.kpjhealth.com.my/api/";

const get_healthpackages = api_server + "packages/healthpackages.php";
const book_health_package = api_server + "packages/book_health_package.php";

//home
const get_homepage = api_server + "homepage/homepage.php";

//auth
const post_register = api_server + "auth/register.php";
const post_login = api_server + "auth/login.php";
const post_otp = api_server + "auth/twilio.php";
const api_token = api_server + "auth/token.php";
const reset_password = api_server + "auth/reset_password.php";
const forgot_password_phone = api_server + "auth/forgot_password_phone.php";

const section1top_banner = api_server + "homepage/section1top_banner.php";
const section2why_us = api_server + "homepage/section2why_us.php";
const section2our_speciality =
  api_server + "homepage/section2our_speciality.php";
const section3healty_package =
  api_server + "homepage/section3healty_package.php";
const section4healty_tourism =
  api_server + "homepage/section4healty_tourism.php";
const section5insights_corner =
  api_server + "homepage/section5insights_corner.php";
const section6award_accre = api_server + "homepage/section6award_accre.php";

//hospital
const get_hospita_detail = api_server + "hospistal/hospital_detail.php";
const get_hospital_listing = api_server + "hospistal/hospital_listing.php";
const get_hospital_individual_detail =
  api_server + "hospistal/hospital_individual_detail.php";

const get_hospital_service = api_server + "hospistal/hospital_service.php";
const get_hospital_coe = api_server + "coe/coe_detail.php";
const get_hospital_patient_care = api_server + "hospistal/patient_care.php";
const get_favicon = api_server + "hospistal/favicon.php";
const get_third_party_scripts = api_server + "settings/third-party-scripts.php";

//profile
const get_notification = api_server + "profile/notification.php";
const cancel_appointment = api_server + "profile/cancel_appointment.php";
const get_appointment = api_server + "profile/appointment.php";
const get_history = api_server + "profile/history/history.php";

//collection
const collection_health_package =
  api_server + "profile/collection/health_package.php";
const collection_get_doctor = api_server + "profile/collection/doctor.php";
const collection_get_podcast = api_server + "profile/collection/podcast.php";
const collection_get_article = api_server + "profile/collection/article.php";
const collection_get_video = api_server + "profile/collection/video.php";
const collection_remove =
  api_server + "profile/collection/remove_collection.php";
const add_collection = api_server + "profile/collection/add_collection.php";

// my purchase
const my_purchase = api_server + "profile/my-purchase/purchase.php";

// const collection_podcast = api_server + "collection/podcast.php";
// const collection_video = api_server + "collection/video.php";
// const collection_article = api_server + "collection/article.php";

//account setting
const get_account = api_server + "profile/setting/account/account.php";
const edit_account = api_server + "profile/setting/account/edit_account.php";
//member
const edit_member =
  api_server + "profile/setting/account/member/edit_member.php";

//card
const get_card = api_server + "profile/setting/account/card/get_card.php";

const edit_card = api_server + "profile/setting/account/card/edit_card.php";
//address
const edit_address =
  api_server + "profile/setting/account/address/edit_address.php";
//notification
const edit_notification =
  api_server + "profile/setting/account/edit_notification.php";

//doctor
const get_doctor_listing = api_server + "doctor/doctor_listing.php";
const get_em_doctor_listing =
  api_server + "doctor/doctor_encoremed_listing.php";
const get_em_doctor_tenantcode = api_server + "encoremed/doctor.php";
const get_doctor_by_emid = api_server + "doctor/doctor_encoremed_detail.php";

//doctor
const get_doctor_individual =
  api_server + "doctor/doctor_individual_detail.php";

const add_collection_doctor = api_server + "doctor/bookmark_doctor.php";
const remove_collection_doctor = api_server + "doctor/unbookmark_doctor.php";

//KPJCareApp
const get_caresapp = api_server + "cares/caresapp.php";

//kpjapp
const get_cares = api_server + "cares/kpj-cares-page.php";
const get_cares_v1 = api_server + "cares/cares.php";
const cares_form = api_server + "cares/caresform.php";
const get_cares_faq = api_server + "cares/kpj_cares_faq.php";
const get_cares_platinum = api_server + "cares/kpj_cares_platinumplus.php";
const get_cares_terms = api_server + "cares/kpj_cares_terms.php";

//health packages
//const get_health_packages_listing =
//  api_server + "packages/healthpackages_listing.php";

const get_health_packages_listing =
  api_server + "healthpackage/healthpackage.php";

const get_health_packages_individual =
  api_server + "packages/healthpackages_individual.php";

const add_collection_healthpackage =
  api_server + "packages/bookmark_package.php";
const remove_collection_healthpackage =
  api_server + "packages/unbookmark_package.php";

//seo link
const get_seo_link = api_server + "seo/seo.php";

//ipc
const get_ipc = api_server + "IPC/IPC.php";
const post_ipc = api_server + "IPC/IPC_form.php";

//speciality services
const get_speciality = api_server + "specialist/specialist.php";
const get_speciality_services =
  api_server + "specialist/specialist_service.php";
const get_subspeciality = api_server + "doctor/doctor_mainspec_listing.php";

//campaign promotions
const get_campro_listing =
  api_server + "campaign_promotion/campaign_promotion.php";

//podcast
const get_podcast_listing = api_server + "insight-corner/podcast.php";
const get_video_listing = api_server + "insight-corner/video.php";
const get_article_listing = api_server + "insight-corner/article.php";
const add_collection_podcast =
  api_server + "insight-corner/bookmark_podcast.php";
const remove_collection_podcast =
  api_server + "insight-corner/unbookmark_podcast.php";

const add_collection_article =
  api_server + "insight-corner/bookmark_article.php";
const remove_collection_article =
  api_server + "insight-corner/unbookmark_article.php";

const add_collection_video = api_server + "insight-corner/bookmark_video.php";
const remove_collection_video =
  api_server + "insight-corner/unbookmark_video.php";

//event
const get_event_listing = api_server + "event/event.php";

//pages
const health_tourism = api_server + "healthtourism/healthtourism-page.php";

//contact us
const get_contact_us = api_server + "contact-us/contact-us-page.php";
const post_contact_us = api_server + "contact-us/submit-form.php";

const teleconsultpage = api_server + "teleconsult/teleconsult-page.php";
const teleconsultpageHospital = api_server + "teleconsult/teleconsult-hospital.php";
const insightcorner = api_server + "insight-corner/insight.php";
const insightcorner_type = api_server + "insight-corner/master/type.php";
const insightcorner_topic = api_server + "insight-corner/master/topic.php";
const insightcorner_category =
  api_server + "insight-corner/master/category.php";
const insightcorner_doctor = api_server + "insight-corner/master/doctor.php";
const faqpage = api_server + "faq/faq.php";
//teleconsult

// appointment
const create_apppointment = api_server + "appointment/create.php";
const update_appointment_url = api_server + "appointment/update.php";
const get_apppointment_list = api_server + "appointment/getAll.php";

// cart
const addtocart = api_server + "/cart/addtocart.php";
const getcart = api_server + "/cart/getcart.php";

//localisation
const get_country = api_server + "/localisation/country.php";
const get_gender = api_server + "/localisation/gender.php";
// language
const get_language = api_server + "/language/language.php";
const get_spokenlanguage = api_server + "/language/spokenlanguage.php";

// order
const add_healthpackage_order = api_server + "/order/add-healthpackage.php";
const reorder_healthpackage_order =
  api_server + "/order/reorder-healthpackage.php";

// mail
const mail = api_server + "/mail/mail.php";
const forgot_password_mail = api_server + "/mail/forgot.php";
const appointment_mail = api_server + "/mail/appointment.php";
const purchased_package_mail = api_server + "/mail/purchased.php";
const otp_mail = api_server + "/mail/otp.php";

// tender
const tender_list = api_server + "/tender/list.php";
const tender_page = api_server + "/page/tender.php";

// news
const news_list = api_server + "/news/list.php";
const news_page = api_server + "/page/news.php";
const news_category_list = api_server + "/news/categoryList.php";

// career
const career_page = api_server + "/page/career.php";
const career_list = api_server + "/career/list.php";
const career_add = api_server + "/career/add.php";

// meta data
const meta_data = api_server + "/page/meta-data.php";

// gl submission
const gl_add = api_server + "/gl-submission/create.php";

// klinik waqaf an-nur
const get_klinik_waqaf_an_nur = api_server + "/page/klinik.php";

// medical card
const get_medical_card = api_server + "/medical-card/get.php";

// settings
const get_settings = api_server + "/settings/general.php";
const get_gl_pdpa = api_server + "/settings/gl-pdpa.php";
const get_career_settings = api_server + "/settings/career.php";
const get_consultant_settings = api_server + "/settings/consultant.php";

// firebase auth API
const post_firebase_sso = api_server + "/auth/firebase_login.php";

// static block
const get_static_block = api_server + "/static-block/get.php";
const submit_static_block = api_server + "/static-block/submit.php";

// search
const search = api_server + "/search/index.php";

// encoremed API
const em_server_api = api_server + "/encoremed/api.php";

// remidi
const sync_profile_api = api_server + "/remidi/sync-profile.php";
const remidi_server_api = api_server + "/remidi/api.php";

module.exports = {
  api_token,
  api_config,
  get_healthpackages,
  book_health_package,
  get_appointment,
  get_notification,
  cancel_appointment,
  post_otp,
  post_login,
  section1top_banner,
  section2why_us,
  section2our_speciality,
  section3healty_package,
  section4healty_tourism,
  section5insights_corner,
  section6award_accre,
  post_register,
  collection_health_package,
  collection_get_doctor,
  collection_get_podcast,
  collection_get_article,
  collection_get_video,
  collection_remove,
  add_collection,
  get_hospita_detail,
  get_hospital_listing,
  get_hospital_individual_detail,
  get_hospital_service,
  get_doctor_individual,
  get_caresapp,
  get_cares,
  get_cares_v1,
  get_account,
  edit_account,
  get_history,
  edit_member,
  edit_card,
  edit_address,
  edit_notification,
  get_doctor_listing,
  cares_form,
  get_health_packages_listing,
  get_health_packages_individual,
  get_seo_link,
  get_ipc,
  post_ipc,
  get_homepage,
  get_speciality,
  get_campro_listing,
  get_event_listing,
  health_tourism,
  get_contact_us,
  teleconsultpage,
  teleconsultpageHospital,
  insightcorner,
  insightcorner_type,
  insightcorner_topic,
  insightcorner_category,
  insightcorner_doctor,
  faqpage,
  get_podcast_listing,
  get_video_listing,
  get_article_listing,
  create_apppointment,
  update_appointment_url,
  get_apppointment_list,
  get_em_doctor_listing,
  addtocart,
  getcart,
  my_purchase,
  post_contact_us,
  add_collection_podcast,
  remove_collection_podcast,
  add_collection_article,
  remove_collection_article,
  add_collection_video,
  remove_collection_video,
  get_country,
  get_gender,
  get_card,
  get_language,
  get_spokenlanguage,
  add_healthpackage_order,
  reorder_healthpackage_order,
  add_collection_healthpackage,
  remove_collection_healthpackage,
  add_collection_doctor,
  remove_collection_doctor,
  get_hospital_coe,
  get_hospital_patient_care,
  mail,
  otp_mail,
  forgot_password_mail,
  appointment_mail,
  purchased_package_mail,
  get_em_doctor_tenantcode,
  reset_password,
  get_speciality_services,
  get_subspeciality,
  forgot_password_phone,
  tender_list,
  tender_page,
  news_list,
  news_page,
  news_category_list,
  career_page,
  career_list,
  career_add,
  get_favicon,
  get_third_party_scripts,
  get_doctor_by_emid,
  meta_data,
  get_cares_faq,
  get_cares_platinum,
  get_cares_terms,
  gl_add,
  get_klinik_waqaf_an_nur,
  get_medical_card,
  get_settings,
  get_gl_pdpa,
  post_firebase_sso,
  get_career_settings,
  get_static_block,
  get_consultant_settings,
  submit_static_block,
  search,
  em_server_api,
  sync_profile_api,
  remidi_server_api
};
